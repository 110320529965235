import Axios from "@/config/backend-service";
import AxiosCustomer from "@/config/customer-service";



const state = {
  form: {},
  editedUserStore: {
    image_path: "",
    point: "",
    category: "",
    detail: "",
    name: "",
    type: "upload",
    start_date: null,
    end_date: null,
    schema_survey: {},
  },
  editedIndexStore: {},
  missionDetail: {},
  data: {},
  category: [],
};

const mutations = {
  SET_EDIT(state, payload) {
    state.editedUserStore = payload.editedUser;
    state.editedIndexStore = payload.editedIndex;
  },
  SET_MISSIONDETAIL(state, payload) {
    state.missionDetail = payload.missionDetail;
  },
  SET_FORM(state, payload) {
    state.form = payload;
  },
  SET_DATA_SITE(state, payload) {
    state.data = payload;
  },
  SET_CATEGORY(state, payload) {
    let data = [
      {
        category: "all",
        label: "all",
        icon: "order-bool-ascending",
      },
      {
        category: "recommend",
        label: "recommend",
        icon: "star-circle-outline",
      },
    ];
    payload.map((item) => {
      data.push(item);
    });
    state.category = data;
  },
  SET_MISSIONS(state, payload) {
    state.missions = payload;
  },
};

const actions = {
  async fetchMission({ commit }) {
    try {
      const { data } = await AxiosCustomer.get("mission/listMission", {
        params: { status: "all" },
      });
      if (data.status == "success") {
        let dataMission = data.data.data;

        commit("SET_MISSIONS", dataMission);
      }
    } catch (error) {
     console.log('error :>> ', error);
    }
  },
  getDataSite({ commit }) {
    Axios.get("getSiteInfo", {
      params: { domain_name: window.location.origin },
    })
      .then(({ data }) => {
        if (data.status == "success") {
          let dataSite = data.site;
          commit("SET_DATA_SITE", dataSite);
        }
      })
      .catch((err) => {
        this.$swal.fire({
          icon: "error",
          title: JSON.stringify(err.response.data),
          showConfirmButton: false,
          timer: 3000,
        });
      });
  },
};


const getters = {
  getForm(state) {
    if (state.form) {
      return ruleRequire(state.form.mySchema);
    }
  },
  getModel(state) {
    if (state.form) {
      return getModel(state.form.mySchema);
    }
  },
  getCorrect(state) {
    if (state.form) {
      return getCorrect(state.form.mySchema);
    }
  },
  getExpectNum(state) {
    if (state.form) {
      return state.form.expected_num;
    }
  },
  getExpectType(state) {
    if (state.form) {
      return state.form.expected_type;
    }
  },
  getCoin(state) {
    return state.data.point;
  },
  getMissions(state) {
    return state.missions;
  },
};
const required = (msg) => (v) => !!v || msg;
const requiredNormal = required("*จำเป็น");
const requiredImage = (number) => (v) =>
  (!!v && v.length < number + 1) || `ไม่ควรใส่เกิน ${number} จำนวน`;

function ruleRequire(schema) {
  let item = Object.keys(schema);
  let arraySchema = [];

  for (let index = 0; index < item.length; index++) {
    let ref_id = schema[item[index]];
    if (schema[item[index]].schema[item[index]] == undefined) {
      continue;
    }

    if (
      schema[item[index]].isRequire == true ||
      schema[item[index]].isRequire == undefined
    ) {
      ref_id.schema[item[index]].rules = [requiredNormal];
      // rule ของจำนวนมีอัพโหลดได้
      if (ref_id.schema[item[index]].numberFile) {
        ref_id.schema[item[index]].rules.push(
          requiredImage(ref_id.schema[item[index]].numberFile)
        );
      }
    }

    // visibleif step1
    if (schema[item[index]].visibleData) {
      let { ref, value, refTarget } = schema[item[index]].visibleData;
      arraySchema.push({
        ref,
        value,
        refTarget,
      });
    }
  }
  // visibleif step2
  if (arraySchema.length > 0) {
    let setSchemaData = reduceSchama(arraySchema, "ref");
    setVisible2(setSchemaData, schema);
  }

  return schema;
}
function setVisible2(arrayVisible, schema) {
  Object.keys(arrayVisible).map((vis) => {
    schema[vis].schema[vis].toCtrl = (data) => {
      arrayVisible[vis].map((item) => {
        if (data.value == item.value) {
          schema[item.refTarget].hidden = false;
          schema[item.refTarget].isRequire = true;
          schema[item.refTarget].schema[item.refTarget].rules = [
            requiredNormal,
          ];
        } else {
          schema[item.refTarget].hidden = true;
          schema[item.refTarget].isRequire = false;
          schema[item.refTarget].schema[item.refTarget].rules = [];
        }
      });
      console.log(" schema[refTarget].hidden :>> ", schema);
    };
  });

  return schema;
}

function reduceSchama(arrayData, property) {
  return arrayData.reduce(function(total, obj) {
    let key = obj[property];
    if (!total[key]) {
      total[key] = [];
    }
    total[key].push(obj);
    return total;
  }, {});
}

function getModel(schema) {
  console.log("schema :>> ", schema);

  let model = Object.keys(schema);
  let obj = model.reduce(function(acc, curr) {
    var object = {};
    object[curr] = "";
    acc[curr] = object;
    return acc;
  }, {});
  return obj;
}

function getCorrect(schema) {
  let item = Object.keys(schema);
  let val = {};

  for (let index = 0; index < item.length; index++) {
    let correct = schema[item[index]].schema[item[index]].correct;
    let point = schema[item[index]].point;
    let ref_id = item[index];
    val[ref_id] = { correct: correct, point: point };
  }
  return val;
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
